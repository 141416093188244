import {createStore, applyMiddleware} from 'redux'
import socketManager from './socketManager'
import buildAuthorizeUrl from './buildAuthorizeUrl'
import buildLogoutUrl from './buildLogoutUrl'


const store = createStore(reducer, getInitState(), applyMiddleware(triggers))
export default store
var siteMeta


store.assertMeta = function (siteMetadata) {
  if (!siteMeta && siteMetadata) {
    siteMeta = {...siteMetadata}

    socketManager.init({
      store,
      ...siteMetadata,
    })
  }
}


function getInitState() {
  return {
    socket_ready: false,
    socket_status: 'pending', // pending, ready, retry, failed
    auth_state_sync: false,
    pending_auth_code: null,
    pending_auth_code_location: null, // ! HACKHACK

    current_user_vuid: null,
    current_user_email: null,
    current_user_first_name: null,
    current_user_last_name: null,
    current_user_cog_sub_id: null,
    current_user_system_role: null,
    current_user_deployment_roles: null,
    current_user_deployment_data: null,
    current_user_vera_account_status: null,
    current_user_vera_password_status: null,

  }
}


function reducer(prevState, action) {

  /* eslint-disable-next-line no-console */
  console.log(action)


  if (!prevState) {
    /* eslint-disable-next-line no-param-reassign */
    prevState = getInitState()
  }


  if (action.type === 'SOCKET_UP') {
    return {
      ...prevState,
      socket_ready: true,
      socket_status: 'ready',
    }
  }


  if (action.type === 'SOCKET_DOWN') {
    return {
      ...prevState,
      socket_ready: false,
      // TODO: socket_status (need distinct granular actions)
    }
  }


  if (action.type === 'RECEIVE_AUTH_STATE') {
    return {
      ...prevState,
      auth_state_sync: true,
      new_user: !action.data.current_user_vuid,
      current_user_vuid: action.data.current_user_vuid || null,
      current_user_email: action.data.current_user_email || null,
      current_user_first_name: action.data.current_user_first_name || null,
      current_user_last_name: action.data.current_user_last_name || null,
      current_user_cog_sub_id: action.data.current_user_cog_sub_id || null,
      current_user_system_role: action.data.current_user_system_role || null,
      current_user_deployment_roles: action.data.current_user_deployment_roles || null,
      current_user_deployment_data: action.data.current_user_deployment_data || null,
      current_user_vera_account_status: action.data.current_user_vera_account_status || null,
      current_user_vera_password_status: action.data.current_user_vera_password_status || null,
    }
  }


  if (action.type === 'CLEAR_AUTH_STATE') {
    return {
      ...prevState,
      auth_state_sync: true,
      pending_auth_code: null,
      pending_auth_code_location: null, // ! HACKHACK
      new_user: false,
      current_user_vuid: null,
      current_user_email: null,
      current_user_first_name: null,
      current_user_last_name: null,
      current_user_cog_sub_id: null,
      current_user_system_role: null,
      current_user_deployment_roles: null,
      current_user_deployment_data: null,
      current_user_vera_account_status: null,
      current_user_vera_password_status: null,
    }
  }


  if (action.type === 'UI_LOGIN') {
    return {
      ...prevState,
    }
  }


  if (action.type === 'UI_LOGOUT') {
    return {
      ...prevState,
    }
  }


  if (action.type === 'RECEIVE_AUTH_CODE') {
    return {
      ...prevState,
      pending_auth_code: action.data.auth_code,
      pending_auth_code_location: action.data.location, // ! HACKHACK
    }
  }


  if (action.type === 'CLEAR_PENDING_AUTH_CODE') {
    return {
      ...prevState,
      pending_auth_code: null,
      pending_auth_code_location: null, // ! HACKHACK
    }
  }




  return prevState
}


function triggers({dispatch, getState}) {
  return function (next) {
    return function (action) {

      if (action.type === 'SOCKET_UP') {
        const currentState = getState()

        if (currentState.pending_auth_code) {
          socketManager.submitAuthCode({
            auth_code: currentState.pending_auth_code,
            location: currentState.pending_auth_code_location,
          })
        }
      }


      if (action.type === 'RECEIVE_AUTH_CODE') {
        next(action)
        const currentState = getState()

        if (currentState.socket_ready) {
          socketManager.submitAuthCode({
            auth_code: action.data.auth_code,
            location: action.data.location,
          })
        }

        return
      }


      if (action.type === 'UI_LOGIN') {
        window.location.href = buildAuthorizeUrl({
          authorize_url: siteMeta.oauth_authorize_url,
          client_id: siteMeta.oauth_client_id,
          callback_url: `${window.location.protocol}//${window.location.host}/welcome`,
        })
      }


      if (action.type === 'UI_LOGOUT') {
        socketManager.socket.emit('uiLogout', {}, function (res) {
          window.location.href = buildLogoutUrl({
            logout_url: siteMeta.oauth_logout_url,
            client_id: siteMeta.oauth_client_id,
            callback_url: `${window.location.protocol}//${window.location.host}/`,
          })
        })
      }




      next(action)
    }
  }
}

import * as SocketClient from 'socket.io-client'


const socketManager = {}


socketManager.init = function ({
  store,
  portal_id,
  gateway,
  pf_path,
  deployment_id,
  deployment_name_long,
  deployment_name_short,
  oauth_client_id,
  oauth_authorize_url,
  oauth_logout_url,
}) {

  if (socketManager.socket || typeof window === 'undefined') return

  var socketKey

  fetch(gateway + pf_path, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      pid: portal_id,
      st: localStorage.getItem('st') || null,
    }),
  }).then(function (res) {
    return res.json()
  }).then(function (res) {
    socketKey = res.sk

    if (res.st) {
      localStorage.setItem('st', res.st)
    }

    socketManager.socket = SocketClient(gateway, {
      // autoConnect: false,
      transports: ['websocket'],
      query: {
        pid: portal_id,
        sk: socketKey || null,
      },
    })

    socketManager.socket.on('connect', function () {
      socketManager.socket.emit('confirmSocket', {
        st: localStorage.getItem('st') || null,
      }, function (res) {
        store.dispatch({
          type: 'SOCKET_UP',
          data: {},
        })
      })
    })

    socketManager.socket.on('disconnect', function () {
      // log
      // show ui error overlay

      store.dispatch({
        type: 'SOCKET_DOWN',
        data: {},
      })
    })

    socketManager.socket.on('uiPush', function (pushedAction) {
      store.dispatch({
        type: pushedAction.uiPushType,
        data: pushedAction.uiPushData,
      })
    })

    socketManager.submitAuthCode = function ({auth_code, location}) {
      if (!auth_code) return

      socketManager.socket.emit('presentAuthCode', {
        auth_code: auth_code,
        redirect_url: `${location.protocol}//${location.host}/welcome`,
      }, function (res) {
        // ?
      })

      store.dispatch({
        type: 'CLEAR_PENDING_AUTH_CODE',
        data: {},
      })
    }

    socketManager.uiSubscribe = function uiSubscribe(entity, query, cb) {
      var eventName

      socketManager.socket.emit('uiSubscribe', {entity: entity, query: query}, function (resData) {
        eventName = `subscription:${resData.subscriptionKey}`
        socketManager.socket.on(eventName, cb)
        if (resData.seed) cb(resData.seed)
      })

      return function () {
        socketManager.socket.off(eventName, cb)
      }
    }

    socketManager.uiGet = function uiGet(entity, query, cb) {
      socketManager.socket.emit('uiGet', {entity: entity, query: query}, function (resData) {
        if (cb) cb(resData)
      })
    }

    socketManager.uiAction = function uiAction(uiActionType, uiActionData, cb) {
      socketManager.socket.emit('uiAction', {uiActionType: uiActionType, uiActionData: uiActionData}, function (uiActionResponse) {
        if (cb) cb(uiActionResponse)
      })
    }

  }).catch(function (e) {
    // log
    // show ui error overlay

    console.log(`preflight error: ${e.message}`)
  })
}




// TODO: log & show ui error overlay when socket is disconnected and reconnet attempts fail

export default socketManager
